/* eslint-disable */
import $ from "jquery";
import Cookies from "js-cookie";

$(function() {
  $("#facility-invite-button").on("click", function() {
    var facility = $(this).attr("data-facility");
    var invitedFacilities = Cookies.get("js-inited-facilities");

    if (invitedFacilities) {
      var ifa = invitedFacilities.split(",");

      if (ifa.includes(facility)) {
        alert("You already invited this facility.");
        $("#facility-invite-button").text("Already invited!");
        $("#facility-invite-button").prop("disabled", true);
        return;
      }

      invitedFacilities += "," + facility;
      Cookies.set("js-inited-facilities", invitedFacilities, { expires: 365 });
    } else {
      invitedFacilities = facility;
    }

    $.ajax(
      "https://api.findyourvillage.co/api/v1/facilities/" +
        facility +
        "/interest",
      {
        data: JSON.stringify({ more_info: true }),
        type: "POST",
        processData: false,
        contentType: "application/json",
        crossDomain: true
      }
    ).done(function(res) {
      $("#facility-invite-button").text("Thanks, invited!");
      $("#facility-invite-button").prop("disabled", true);
    });
  });

  $("#budget-calculator-form").submit(function(e) {
    e.preventDefault();

    var form = $(this);
    var url = form.attr("action");

    var state = $("#budget-calculator-state").val();
    var childAge = $('input[name="child_age"]:checked').val();
    var careType = $("input[name=care_type]:checked").val();
    var scheduleType = $("input[name=schedule_type]:checked").val();

    if (state == "" || state == null) {
      alert("Please select a state.");
      $("#budget-calculator-state").focus();
      return true;
    }

    var stateText = $("#budget-calculator-state option:selected").text();
    var childAgeText = $('input[name="child_age"]:checked')
      .next()
      .text();
    var careTypeText = $("input[name=care_type]:checked")
      .next()
      .text();
    var scheduleTypeText = $("input[name=schedule_type]:checked")
      .next()
      .text();

    url += "?state=" + encodeURIComponent(state);
    url += "&child_age=" + encodeURIComponent(childAge);
    url += "&care_type=" + encodeURIComponent(careType);
    url += "&schedule_type=" + encodeURIComponent(scheduleType);

    $.get(
      url,
      function(data) {
        $("#budget-calculator-card-result")
          .removeClass("d-none")
          .addClass("d-block");
        $("#budget-calculator-card-form").addClass("d-none");
        if (data.price == null) {
          $(".budget-calculator-no-result").removeClass("d-none");
        } else {
          $("#budget-calc-output-care-type").text(careTypeText);
          $("#budget-calc-output-child-age").text(childAgeText);
          $("#budget-calc-output-schedule-type").text(scheduleTypeText);
          $("#budget-calc-output-state").text(stateText);
          var pricePerMonth = parseInt(data.price, 10);
          var pricePerYear = pricePerMonth * 12;
          $("#budget-calculator-price-month").text(sepNum(pricePerMonth));
          $("#budget-calculator-price-year").text(sepNum(pricePerYear));
          $(".budget-calculator-result").removeClass("d-none");
        }
      },
      "json"
    );
  });
  $("#budget-calculator-box-form").submit(function(e) {
    e.preventDefault();

    var form = $(this);
    var url = form.attr("action");

    var state = $("#budget-calculator-state").val();
    var childAge = $('input[name="child_age"]:checked').val();
    var careType = $("input[name=care_type]:checked").val();
    var scheduleType = $("input[name=schedule_type]:checked").val();

    if (state == "" || state == null) {
      alert("Please select a state.");
      $("#budget-calculator-state").focus();
      return false;
    }

    var stateText = $("#budget-calculator-state option:selected").text();
    var childAgeText = $('input[name="child_age"]:checked')
      .next()
      .text();
    var careTypeText = $("input[name=care_type]:checked")
      .next()
      .text();
    var scheduleTypeText = $("input[name=schedule_type]:checked")
      .next()
      .text();

    url += "?state=" + encodeURIComponent(state);
    url += "&child_age=" + encodeURIComponent(childAge);
    url += "&care_type=" + encodeURIComponent(careType);
    url += "&schedule_type=" + encodeURIComponent(scheduleType);

    $.get(
      url,
      function(data) {
        $(".container-calculate").addClass("d-none");
        $(".container-result").removeClass("d-none");
        if (data.price == null) {
          $(".budget-calculator-box-no-result").removeClass("d-none");
        } else {
          $("#budget-calc-output-care-type").text(careTypeText);
          $("#budget-calc-output-child-age").text(childAgeText);
          $("#budget-calc-output-schedule-type").text(scheduleTypeText);
          $("#budget-calc-output-state").text(stateText);
          var pricePerMonth = parseInt(data.price, 10);
          var pricePerYear = pricePerMonth * 12;
          $("#budget-calculator-box-price-month").text(sepNum(pricePerMonth));
          $("#budget-calculator-box-price-year").text(sepNum(pricePerYear));
          $(".budget-calculator-box-result").removeClass("d-none");
        }
      },
      "json"
    );
  });
});
$("#join-waitliste-submit").on("click", function() {
  var email = $.trim($("#join-waitliste-email").val());

  if (email == "") return;

  $.post(
    "https://api.findyourvillage.co/api/v1/parents/tuition_waitlist",
    JSON.stringify({ email: email }),
    function(data) {
      $("joinWaitlistModal-form").addClass("d-none");
      $("joinWaitlistModal-result")
        .removeClass("d-none")
        .addClass("d-block");
    },
    "json"
  );
});
$("#contact-facility-button").on("click", function() {
  var email = $(this).attr("data-email");
  var phone = $(this).attr("data-phone");

  $("#contactFacilityPhoneRow").show();
  $("#contactFacilityEmailRow").show();

  if (phone == "") {
    $("#contactFacilityPhoneRow").hide();
  }

  if (email == "") {
    $("#contactFacilityEmailRow").hide();
  }

  $("#contactFacilityPhone").text(phone);
  $("#contactFacilityEmail").text(email);

  //$('#contactFacilityModal').modal('show');
});
function sepNum(r, e) {
  return (
    (e = e || ","),
    (r = String(r)
      .replace(/[\u0660-\u0669\u06f0-\u06f9]/g, function(r) {
        return 15 & r.charCodeAt(0);
      })
      .replace(/(?:[^\.]|^)\b(\d+)/g, function(r) {
        return (r = r.replace(/\B(?=(\d{3})+\b)/g, e));
      }))
  );
}
