import $ from 'jquery';

$(document).ready(() => {
  const myBlocks = document.querySelectorAll('.animate-me');

  const config = {
    rootMargin: '0px',
    threshold: 0.25,
  };

  const observer = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > 0.25) {
        entry.target.classList.add('animated');
        observer.unobserve(entry.target);
      }
    });
  }, config);
  myBlocks.forEach((block) => {
    observer.observe(block);
  });
});
