/* eslint-disable func-names */
/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
import '../css/app.scss';

// boostrap
import 'popper.js';
import 'bootstrap';
import 'intersection-observer';
import 'objectFitPolyfill';

import './animations/homepage';

import './misc/misc';
import './misc/apply';
import './misc/search';
import './misc/budget-calculator';
import './lazy-load';

// Need jQuery? Install it with 'yarn add jquery', then uncomment to require it.
import $ from 'jquery';

$(() => {
  $('textarea.autosize')
  // eslint-disable-next-line func-names
    .each(function () {
      this.setAttribute(
        'style',
        `height:${this.scrollHeight}px;overflow-y:hidden;`,
      );
    })
    // eslint-disable-next-line func-names
    .on('input', function () {
      this.style.height = 'auto';
      this.style.height = `${this.scrollHeight}px`;
    });
});

function clearErrors() {
  // clear all errors
}

// eslint-disable-next-line no-unused-vars
function displayError(field, message) {
  // console.log(`${field}: ${message}`);
}

$('.contact-form').on('submit', function (event) {
  event.preventDefault();

  const form = $(this);

  const unindexedArray = form.serializeArray();
  const indexedArray = {};

  $.map(unindexedArray, (row) => {
    indexedArray[row.name] = row.value;
  });

  const url = form.attr('action');
  $.post(url, JSON.stringify(indexedArray), () => {
    form.addClass('d-none');
    $('#contact-form-sent').removeClass('d-none');
  }, 'json')
    .fail((response) => {
      clearErrors();

      const { errors } = response.responseJSON;

      if (errors.length > 0) {
        errors.forEach((error) => {
          displayError(error.field, error.message);
        });
      }
    });
});
