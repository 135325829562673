/* eslint-disable */
import $ from "jquery";

$(() => {
  if ($("#facility-search-results-map-wrapper").length > 0) {
    $("#view-toggle-button").on("click", function() {
      if (
        $("#facility-search-results-map-wrapper").hasClass("map-mobile-visible")
      ) {
        $("#facility-search-results-map-wrapper").removeClass(
          "map-mobile-visible"
        );
        $("#facility-search-results-list").removeClass("hide-mobile-list-view");
        $(this).text("Show Map");
        window.scrollTo(0, 0);
      } else {
        $("#facility-search-results-map-wrapper").addClass(
          "map-mobile-visible"
        );
        $("#facility-search-results-list").addClass("hide-mobile-list-view");
        $(this).text("Hide Map");
        window.scrollTo(0, 0);
      }
    });

    function getParameterByName(name, url) {
      if (!url) url = window.location.href;
      name = name.replace(/[\[\]]/g, "\\$&");
      var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return "";
      return decodeURIComponent(results[2].replace(/\+/g, " "));
    }

    var kw = getParameterByName("filter_keyword");
    $("#filter_keyword").val(kw);

    var locations = [];
    var markers = [];
    var infowindows = [];
    var map;
    var currentPage = 1;
    var searchBounds = "";
    var currentSearchQuery = "x";
    var searching = false;

    function initMap() {
      map = new google.maps.Map(
        document.getElementById("facility-search-results-map"),
        {
          center: { lat: 40.749465, lng: -73.985579 },
          zoom: 16,
          minZoom: 13,
          maxZoom: 18,
          mapTypeControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          zoomControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT
          }
        }
      );

      google.maps.event.addListener(map, "dragend", function() {
        searchInBounds();
      });

      google.maps.event.addListener(map, "zoom_changed", function() {
        searchInBounds();
      });

      google.maps.event.addListenerOnce(map, "idle", function() {
        navigator.geolocation.getCurrentPosition(
          function(position) {
            // Center on user's current location if geolocation prompt allowed
            var location = new google.maps.LatLng(
              position.coords.latitude,
              position.coords.longitude
            );
            map.setCenter(location);
            searchInBounds();
          },
          function(positionError) {
            // User denied geolocation prompt - default to NY
          }
        );
      });
    }

    if (typeof window.shouldInitMap != "undefined") {
      initMap();
    } else {
      window.initMap = initMap;
    }
    console.log("initMap");

    function searchInBounds() {
      var bounds = map.getBounds();
      var ne = bounds.getNorthEast();
      var sw = bounds.getSouthWest();
      searchBounds =
        sw.lat() + "," + sw.lng() + "," + ne.lat() + "," + ne.lng();
      searchFacilities();
    }

    // {0} = link url
    // {1} = cover img url
    // {2} = title
    // {3} = location
    // {4} = description
    // {5} = footer
    var itemTemplate =
      '<a href="{0}" target="_blank" rel="noopener noreferrer" class="facility-search-results-list-item"><span class="cover" style="background-image:url({1})"></span><span class="details"><span class="details-title">{2}</span><span class="details-location">{3}</span><span class="details-desc">{4}</span><span class="details-footer">{5}</span></span></a>';

    var facilityTypeName = {
      id1: "Center Based Care",
      id2: "Home Based Care",
      id3: "School",
      id4: "Preschool",
      id5: "Unclaimed profile"
    };

    function searchFacilities(
      page = 1,
      pageSize = 1000,
      overrideBounds = false
    ) {
      if (searching) {
        return;
      }

      searching = true;

      currentPage = page;

      var url =
        "https://api.findyourvillage.co/api/v1/facilities?page_size=" +
        pageSize +
        "&page=" +
        page;
      var filter_keyword = $.trim($("#filter_keyword").val());

      var isNewSearchQuery = filter_keyword != currentSearchQuery;

      if (filter_keyword != "") {
        if (filter_keyword.length == 5 && $.isNumeric(filter_keyword)) {
          url += "&zip_code=" + encodeURIComponent(filter_keyword);
        } else {
          url += "&city=" + encodeURIComponent(filter_keyword);
        }
      }
      if (searchBounds != "" && filter_keyword == "" && !overrideBounds) {
        url += "&rect=" + searchBounds;
      }

      if (overrideBounds) {
        var c = map.getCenter();
        url += "&lat=" + encodeURIComponent(c.lat());
        url += "&lng=" + encodeURIComponent(c.lng());
      }

      currentSearchQuery = filter_keyword;

      $("#facility-search-results-nav-prev").hide();
      $("#facility-search-results-nav-next").hide();
      $("#facility-search-results-list-items").html("<i>Loading...</i>");

      for (var m = 0; m < markers.length; m++) {
        markers[m].setMap(null);
      }
      markers = [];

      $.get(
        url,
        function(data) {
          var results = data.results;
          var output = "";
          var bounds = new google.maps.LatLngBounds();

          if (Array.isArray(results) && results.length > 0) {
            $.each(results, function(index, item) {
              var o = itemTemplate;
              o = o.replace("{0}", "/facility/" + item.id_facility);
              o = o.replace("{1}", item.photo_url);
              o = o.replace("{2}", item.name);
              o = o.replace(
                "{3}",
                facilityTypeName["id" + item.id_facility_type] +
                  " in " +
                  item.address.city
              );
              o = o.replace("{4}", "");
              o = o.replace("{5}", "See More");
              output += o;

              var marker = new google.maps.Marker({
                position: new google.maps.LatLng(
                  item.address.lat,
                  item.address.lng
                ),
                map: map,
                title: item.name
              });
              if (item.id_facility_type != 5) {
                marker.setIcon("/images/icon_pin.svg");
              } else {
                marker.setIcon("/images/icon_pin_grey.svg");
              }
              markers.push(marker);
              if (index == 0 && isNewSearchQuery == true) {
                bounds.extend(
                  new google.maps.LatLng(
                    marker.position.lat(),
                    marker.position.lng()
                  )
                );
              }

              //INFO CONTENT
              var content =
                '<a class="google-map-info-window" href="/facility/' +
                item.id_facility +
                '" target="_blank" rel="noopener noreferrer"><span class="cover" style="background-image: url(' +
                item.photo_url +
                ')"></span><span class="title">' +
                item.name +
                '</span><span class="desc">' +
                (facilityTypeName["id" + item.id_facility_type] +
                  " in " +
                  item.address.city) +
                "</span></a>";

              (function(marker) {
                google.maps.event.addListener(marker, "click", function() {
                  //SET INFO WINDOW POSITION AND CONTENT
                  window.infowindow = new google.maps.InfoWindow({
                    pixelOffset: new google.maps.Size(0, -0),
                    content: content
                  });
                  infowindows.push(infowindow);

                  // CLOSING ALL OPEND INFO WINDOWS
                  for (var i = 0; i < infowindows.length; i++) {
                    infowindows[i].close();
                  }

                  //OPEN INFO WINDOW
                  infowindow.open(map, marker);
                });
              })(marker);
            });

            //center map
            if (isNewSearchQuery == true) {
              map.fitBounds(bounds);
              map.setZoom(13);
            }

            searching = false;

            //zoom to fit
            /*var zoomChangeBoundsListener =
                      google.maps.event.addListenerOnce(map, 'bounds_changed', function (event) {
                          if (this.getZoom()) {   // or set a minimum
                              this.setZoom(12);  // set zoom here
                          }
                      });

                  setTimeout(function () { google.maps.event.removeListener(zoomChangeBoundsListener) }, 2000); */

            if (data.current_page == 1) {
              $("#facility-search-results-nav-prev").hide();
            } else {
              $("#facility-search-results-nav-prev").show();
            }

            if (data.current_page == data.total_pages) {
              $("#facility-search-results-nav-next").hide();
            } else {
              $("#facility-search-results-nav-next").show();
            }
          } else {
            output =
              '<strong style="color: #030E29!important; font-size: 24px; line-height: 26px;">We are not live in this area yet!<br />Add your email to the waitlist to be notified when we are live here.<br /><br /></strong><button type="button" class="btn btn-primary shadow px-4" data-toggle="modal" data-target="#joinWaitlistModalNoResults">Get started</button>';
            $(
              "#facility-search-results-nav-prev, #facility-search-results-nav-next"
            ).hide();

            searching = false;
          }

          $("#facility-search-results-list-items").html(output);
        },
        "json"
      );
    }

    searchFacilities();

    $("#facility-search-results-nav-prev").on("click", function() {
      searchFacilities(currentPage - 1 < 1 ? 1 : currentPage - 1);
    });

    $("#facility-search-results-nav-next").on("click", function() {
      searchFacilities(currentPage + 1);
    });

    $("#facility-search-button").on("click", function() {
      searchFacilities(1, 100, true);
    });
  }
});
