/* eslint-disable no-param-reassign */
import $ from 'jquery';
import 'devbridge-autocomplete';
import flatpickr from 'flatpickr';

const YearDropdownPlugin = (pluginConfig) => {
  const defaultConfig = {
    text: '',
    theme: 'light',
    date: new Date(),
    yearStart: 100,
    yearEnd: 2,
  };

  const config = {};

  Object.keys(defaultConfig).forEach((key) => {
    if (pluginConfig && pluginConfig[key] !== undefined) {
      config[key] = pluginConfig[key];
    } else {
      config[key] = defaultConfig[key];
    }
  });

  const currYear = new Date().getFullYear();
  const selectedYear = currYear;

  const yearDropdown = document.createElement('select');

  const createSelectElement = (year) => {
    const start = new Date().getFullYear() - config.yearStart;
    const end = currYear + config.yearEnd;

    for (let i = end; i >= start; i -= 1) {
      const option = document.createElement('option');
      option.value = i;
      option.text = i;
      yearDropdown.appendChild(option);
    }

    yearDropdown.value = year;
  };

  return (fp) => {
    // eslint-disable-next-line no-underscore-dangle
    fp.yearSelectContainer = fp._createElement(
      'div',
      `flatpickr-year-select ${config.theme}Theme`,
      config.text,
    );

    fp.yearSelectContainer.tabIndex = -1;
    createSelectElement(selectedYear);
    yearDropdown.addEventListener('change', (evt) => {
      const year = evt.target.options[evt.target.selectedIndex].value;
      fp.changeYear(year);
    });

    fp.yearSelectContainer.append(yearDropdown);

    return {
      onReady: function onReady() {
        const container = fp.currentYearElement.parentNode;

        container.innerHTML = '';
        container.appendChild(fp.yearSelectContainer);
      },
    };
  };
};

$(() => {
  if (typeof facilitySearchUrl !== 'undefined' && typeof applicationUrl !== 'undefined') {
    const stateDropdown = $('#apply_searchState');
    const stateDropdownGroup = stateDropdown.closest('.form-group');
    const autocompleteInputGroup = $('.input-group-autocomplete');

    const applyAutocomplete = $('.apply-autocomplete').first().autocomplete({
      minChars: 2,
      // eslint-disable-next-line no-undef
      serviceUrl: facilitySearchUrl,
      paramName: 'text',
      dataType: 'json',
      deferRequestBy: 300,
      preserveInput: true,
      showNoSuggestionNotice: true,
      noSuggestionNotice: 'No results found.',
      transformResult(response) {
        let facilities = [];
        response.sections.forEach((section) => {
          if (section.name === 'facilities') {
            facilities = section.items;
          }
        });

        const suggestions = [];
        facilities.forEach((facility) => {
          suggestions.push({
            value: `${facility.name}: ${facility.address.address}, ${facility.address.city}, ${facility.address.state} ${facility.address.zip_code}`,
            data: facility,
          });
        });

        return { suggestions };
      },
      onSelect(suggestion) {
        const textInput = $(this);
        const inputGroup = textInput.closest('.input-group');
        const formGroup = inputGroup.closest('.form-group');
        const form = textInput.closest('form');
        const hiddenInput = $('#apply_facilityId');
        hiddenInput.val(suggestion.data.id_facility);

        const outputBox = form.find('.apply-search-selected');
        outputBox.find('.js-name').text(suggestion.data.name);
        outputBox.find('.js-address').text(`${suggestion.data.address.address}, ${suggestion.data.address.city}, ${suggestion.data.address.state} ${suggestion.data.address.zip_code}`);
        outputBox.find('.js-phone').text(suggestion.data.center_phone_number);

        inputGroup.addClass('d-none');
        stateDropdownGroup.addClass('d-none');
        formGroup.addClass('col-md-12');
        outputBox.removeClass('d-none');
      },
      onSearchStart() {
        // show loading spinner
        const suggestionsContainer = $(applyAutocomplete.suggestionsContainer);
        let suggestionsLoadingContainer = suggestionsContainer.find('.autocomplete-suggestions-loading');

        suggestionsContainer.find('.autocomplete-no-suggestion').hide();

        if (suggestionsLoadingContainer.length === 0) {
          const containerElement = document.createElement('div');
          containerElement.setAttribute('class', 'autocomplete-suggestions-loading');
          containerElement.append('Searching...');

          const spinnerElement = document.createElement('div');
          spinnerElement.setAttribute('class', 'loading-spinner');

          for (let i = 0; i < 12; i += 1) {
            spinnerElement.append(document.createElement('div'));
          }

          containerElement.prepend(spinnerElement);
          suggestionsContainer.prepend(containerElement);

          suggestionsLoadingContainer = suggestionsContainer.find('.autocomplete-suggestions-loading');
        }

        suggestionsLoadingContainer.show();
        suggestionsContainer.show();
      },
      onSearchComplete() {
        const suggestionsContainer = $(applyAutocomplete.suggestionsContainer);

        // these won't exist if we have any suggestions
        suggestionsContainer.find('.autocomplete-no-suggestion').show();
        suggestionsContainer.find('.autocomplete-suggestions-loading').hide();
      },
    }).autocomplete();

    // eslint-disable-next-line func-names
    $('.apply-search-selected a').click(function (event) {
      event.preventDefault();
      const outputBox = $(this).closest('.apply-search-selected');
      const textInput = $('#apply_facilityName');
      const inputGroup = textInput.closest('.input-group');
      const formGroup = inputGroup.closest('.form-group');

      textInput.val('');
      inputGroup.removeClass('d-none');
      stateDropdownGroup.removeClass('d-none');
      formGroup.removeClass('col-md-12');
      outputBox.addClass('d-none');
    });

    stateDropdown.change(() => {
      // state selected
      stateDropdown.find('option[value=""]').remove();

      autocompleteInputGroup.removeClass('disabled');
      autocompleteInputGroup.find('input').prop('disabled', false);

      applyAutocomplete.setOptions({
        params: { state: stateDropdown.val() },
      });

      applyAutocomplete.clearCache();
      $(applyAutocomplete).trigger('onValueChange');
    });

    flatpickr('.flatpickr-future', {
      allowInput: true,
      altInput: true,
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d',
      minDate: 'today',
      maxDate: new Date().fp_incr(2 * 365),
      plugins: [
        new YearDropdownPlugin({
          yearStart: 0,
        }),
      ],
    });

    flatpickr('.flatpickr-dob', {
      allowInput: true,
      altInput: true,
      altFormat: 'F j, Y',
      dateFormat: 'Y-m-d',
      maxDate: 'today',
      plugins: [
        new YearDropdownPlugin({
          yearEnd: 0,
        }),
      ],
    });

    // eslint-disable-next-line func-names
    $(document).on('submit', '.s-apply form', function (event) {
      event.preventDefault();

      const form = $(this);
      const formValues = {};

      form.serializeArray().forEach((field) => {
        const name = field.name.replace('apply[', '').replace(']', '');
        Object.defineProperty(formValues, name, {
          value: field.value,
        });
      });

      const fieldApiNames = {
        id_facility: 'facilityId',
        monthly_tuition: 'monthlyTuition',
        start_date: 'startDate',
        first_name: 'firstName',
        last_name: 'lastName',
        date_of_birth: 'dateOfBirth',
        address: 'address',
        zip_code: 'zipCode',
        city: 'city',
        state: 'state',
        ssn: 'ssn',
        email: 'emailAddress',
        phone_number: 'phone',
        age_of_child_months: 'ageOfChild',
        accept_tos: 'termsConsent',
        join_waitlist: 'waitlist',
      };

      const fieldApiNamesFlipped = {};
      $.each(fieldApiNames, (i, el) => {
        fieldApiNamesFlipped[el] = i;
      });

      const request = {
        id_facility: formValues[fieldApiNames.id_facility],
        monthly_tuition: formValues[fieldApiNames.monthly_tuition],
        start_date: formValues[fieldApiNames.start_date],
        first_name: formValues[fieldApiNames.first_name],
        last_name: formValues[fieldApiNames.last_name],
        date_of_birth: formValues[fieldApiNames.date_of_birth],
        address: formValues[fieldApiNames.address],
        zip_code: formValues[fieldApiNames.zip_code],
        city: formValues[fieldApiNames.city],
        state: formValues[fieldApiNames.state],
        ssn: formValues[fieldApiNames.ssn],
        email: formValues[fieldApiNames.email],
        phone_number: formValues[fieldApiNames.phone_number],
        age_of_child_months: formValues[fieldApiNames.age_of_child_months],
        accept_tos: (typeof formValues[fieldApiNames.accept_tos] !== 'undefined' && formValues[fieldApiNames.accept_tos] === '1'),
        join_waitlist: (typeof formValues[fieldApiNames.join_waitlist] !== 'undefined' && formValues[fieldApiNames.join_waitlist] === '1'),
      };

      localStorage.setItem('apply-name', formValues.firstName);

      $.ajax({
        type: 'POST',
        // eslint-disable-next-line no-undef
        url: applicationUrl,
        data: JSON.stringify(request),
        contentType: 'application/json',
        dataType: 'json',
        success() {
          window.location.replace('/apply-success');
        },
        error(jqXHR) {
          const response = jqXHR.responseJSON;

          if (typeof response.error !== 'undefined') {
            const errors = response.error;

            $('.form-error').removeClass('form-error');

            // eslint-disable-next-line no-unused-vars
            $.each(errors, (key, error) => {
              let fieldName = fieldApiNames[key];

              if (key === 'id_facility') {
                fieldName = 'facilityName';
              }

              const input = $(`[name="apply[${fieldName}]"]`);
              const formGroup = input.closest('.form-group');

              if (formGroup.length) {
                formGroup.addClass('form-error');
              }
            });
          }
        },
      });
    });
  }

  // apply success name
  const nameSpans = $('.js-apply-name');

  if (nameSpans.length) {
    const name = localStorage.getItem('apply-name');

    if (name.length) {
      nameSpans.text(`, ${name}`);
    }
  }
});
